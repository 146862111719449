<template>
	<transition
		name="reveal-right"
		@after-enter="$emit('transition')"
		@after-leave="$emit('transition')"
	>
		<section v-if="tool && isOpen" class="tool-help">
			<h4>
				<svg-icon :icon="tool.iconName" fixed />
				{{ tool.label }}
			</h4>
			<component :is="tool.helpComponent" />
			<ast-button class="close" @click.native="isOpen = false">
				<svg-icon icon="close" />
			</ast-button>
		</section>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import AstButton from '@components/Button'
const toolHelpComponents = getToolHelpComponents()

export default {
	name: 'ViewerToolHelp',
	components: {
		AstButton,
		...toolHelpComponents,
	},
	data() {
		return {
			isOpen: true,
			tool: undefined,
		}
	},
	computed: {
		...mapGetters(['availableTools']),
		activeTools() {
			return this.availableTools.filter(tool => tool.binding)
		},
	},
	watch: {
		activeTools(activeTools, previouslyActiveTools) {
			this.$nextTick(() => {
				if (!activeTools || !activeTools.length) return
				const currentToolWasDeactivated =
					this.tool && activeTools.every(a => a.label !== this.tool.label)
				// get newly activated tool(s)
				let newlyActiveTools = activeTools
				if (previouslyActiveTools && previouslyActiveTools.length) {
					newlyActiveTools = activeTools.filter(
						a => !previouslyActiveTools.some(p => p.alias === a.alias)
					)
				}
				// check if a new tool has a help component
				let newTool = newlyActiveTools.find(activeTool => {
					return activeTool.alias in toolHelpComponents
				})
				// check if there is another active, help-able tool
				const fallbackTool = activeTools.find(activeTool => {
					return (
						this.tool &&
						activeTool.alias in toolHelpComponents &&
						activeTool.label !== this.tool.label
					)
				})
				// if there is no new tool, but there is still a help-able tool active, use that one
				if (!newTool && currentToolWasDeactivated && fallbackTool) newTool = fallbackTool
				if (newTool) {
					// open help for the new help-able tool
					this.tool = {
						iconName: newTool.iconName,
						label: newTool.label,
						helpComponent: newTool.alias,
					}
					this.isOpen = true
				} else if (
					// otherwise, close help if previous help tool was deselected or user manually closed help for it
					!this.tool ||
					!this.isOpen ||
					currentToolWasDeactivated
				) {
					this.tool = undefined
					this.isOpen = false
				}
			})
		},
	},
}

function getToolHelpComponents() {
	const toolHelpFiles = require.context('@components/layout/ViewerToolHelp', false, /\w+\.vue$/i)
	let components = {}
	toolHelpFiles.keys().forEach(filename => {
		const componentName = filename.replace(/^\.\/(.*)\.\w+$/, '$1')
		components[componentName] = toolHelpFiles(filename).default
	})
	return components
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';

.tool-help {
	position: relative;
	padding: 12px 1.5em 12px 12px; // right padding is for scrollbar

	h4,
	ol {
		width: 176px;
	}

	h4 {
		text-transform: uppercase;
		letter-spacing: 0.05em;
		font-weight: normal;
		margin-bottom: 1em;
	}

	.close {
		background: transparent;
		color: var(--secondary-label);
		border: 0;
		position: absolute;
		top: 0;
		right: 0.5em;
	}
}
</style>
